
import { defineComponent } from 'vue';
import { mapState } from 'vuex';
import { showError } from '@/sweetalert2/templates.ts';
import { CommonError, User } from '@/models';

export default defineComponent({
  data () {
    return {
      username: '',
      password: '',
      saveUsername: true
    };
  },
  mounted () {
    this.saveUsername = localStorage.getItem('saveUsername') === 'true';
    this.username = localStorage.getItem('username') ?? '';
  },
  methods: {
    async login () {
      let [username, password] = [this.username, this.password];
      const validInputs = await this.validateInputs(username, password);
      if (!validInputs) {
        return;
      }
      if (!username.includes('@')) {
        username = username.concat('@sz-ybbs.ac.at');
      }
      try {
        await this.$store.dispatch('login', { email: username, password });
      } catch (e) {
        await showError(this.$t("messages.invalid user/pass"));
        return;
      }
      this.storeUsername(username);
      const redirect = this.$route.query.redirect as string;
      const path = redirect ?? User.getLandingPage(this.$store.state.user.type);
      await this.$router.replace(path);
    },
    storeUsername (username: string) {
      if (this.saveUsername) {
        localStorage.setItem('username', username);
      } else {
        localStorage.removeItem('username');
      }
      localStorage.setItem('saveUsername', JSON.stringify(this.saveUsername));
    },
    async validateInputs (username: string, password: string): Promise<boolean> {
      if (username.length === 0 || password.length === 0) {
        await showError(this.$t("messages.fill all fields"));
        return false;
      }
      return true;
    }
  },
  computed: {
    ...mapState(['user'])
  }
});

import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, vModelCheckbox as _vModelCheckbox, toDisplayString as _toDisplayString, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "form-group mb-4" }
const _hoisted_2 = ["placeholder"]
const _hoisted_3 = { class: "form-group mb-4" }
const _hoisted_4 = ["placeholder"]
const _hoisted_5 = { class: "form-group mb-4" }
const _hoisted_6 = { class: "custom-control custom-checkbox" }
const _hoisted_7 = {
  for: "customCheck1",
  class: "custom-control-label"
}
const _hoisted_8 = {
  class: "btn btn-primary shadow px-5",
  name: "submit"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("form", {
    onSubmit: _cache[3] || (_cache[3] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.login && _ctx.login(...args)), ["prevent"]))
  }, [
    _createElementVNode("div", _hoisted_1, [
      _withDirectives(_createElementVNode("input", {
        type: "text",
        id: "email",
        name: "email",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => (_ctx.username = $event)),
        placeholder: _ctx.$t('messages.E-mail / username'),
        class: "form-control border-0 shadow form-control-lg"
      }, null, 8, _hoisted_2), [
        [_vModelText, _ctx.username]
      ])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        type: "password",
        id: "password",
        name: "password",
        "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => (_ctx.password = $event)),
        placeholder: _ctx.$t('messages.password'),
        class: "form-control border-0 shadow form-control-lg text-violet"
      }, null, 8, _hoisted_4), [
        [_vModelText, _ctx.password]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _createElementVNode("div", _hoisted_6, [
        _withDirectives(_createElementVNode("input", {
          id: "customCheck1",
          type: "checkbox",
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => (_ctx.saveUsername = $event)),
          class: "custom-control-input"
        }, null, 512), [
          [_vModelCheckbox, _ctx.saveUsername]
        ]),
        _createElementVNode("label", _hoisted_7, _toDisplayString(_ctx.$t("messages.save email")), 1)
      ])
    ]),
    _createElementVNode("button", _hoisted_8, _toDisplayString(_ctx.$t("messages.Sign in")), 1)
  ], 32))
}
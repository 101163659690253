
import { defineComponent } from 'vue';
import { User, errorPrepend } from '@/models';
import { showError } from '@/sweetalert2/templates';

export default defineComponent({
  name: 'MicrosoftLoginButton',
  methods: {
    async login () {
      try {
        await this.$store.dispatch('loginAd');
        await this.$router.replace(User.getLandingPage(this.$store.state.user.type));
      } catch (e) {
        await showError(this.$t("messages.error ms login") + errorPrepend());
      }
    }
  }
});
